import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VDPopupListbox = _resolveComponent("VDPopupListbox")!

  return (_openBlock(), _createBlock(_component_VDPopupListbox, {
    ref: "listbox",
    dataKey: "roleId",
    disabled: _ctx.disabled,
    onOptionAdded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('addedRole', $event))),
    disabledOptions: _ctx.addedRoles,
    filterFields: ['name', 'application.name'],
    isLoading: _ctx.loading,
    groupedOptions: _ctx.roles,
    appendTo: "#sidebar"
  }, {
    default: _withCtx(({ option }) => [
      _createTextVNode(_toDisplayString(option.name), 1)
    ]),
    _: 1
  }, 8, ["disabled", "disabledOptions", "filterFields", "isLoading", "groupedOptions"]))
}