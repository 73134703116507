
import { VDPopupListboxClass } from "@/components/pickers/VDPopupListbox";
import VDPopupListbox from "@/components/pickers/VDPopupListbox.vue";
import {
  GetRolesQuery,
  GetApplicationQuery,
  RoleType,
  useGetRolesQuery,
  useGetApplicationQuery,
} from "@/graphql/types";
import GroupedItems from "@/models/GroupedItems";
import { useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { defineComponent, PropType, Ref, ref, watch, computed } from "vue";

gql`
  query getRoles {
    roles {
      roleId
      name
      application {
        name
      }
    }
  }
`;

export default defineComponent({
  components: {
    VDPopupListbox,
  },
  emits: ["addedRole"],
  props: {
    addedRoles: {
      type: Array as PropType<RoleType[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    applicationId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    // VDOM
    const listbox = ref<VDPopupListboxClass>();

    // Refs
    const isEnabled = ref(false);

    // Data
    const getRolesResponse = useGetRolesQuery(() => ({
      enabled: isEnabled.value && props.applicationId == undefined,
      fetchPolicy: "network-only",
    }));

    var getApplicationResponse = useGetApplicationQuery(
      {
        applicationId: props.applicationId,
      },
      () => ({
        enabled: isEnabled.value && props.applicationId != undefined,
        fetchPolicy: "network-only",
      })
    );

    //Computed
    const loading = computed(() => {
      return (
        getRolesResponse.loading.value || getApplicationResponse.loading.value
      );
    });

    const error = computed(() => {
      return getRolesResponse.error.value || getApplicationResponse.error.value;
    });

    return {
      roles: props.applicationId
        ? useGroupedRolesByApplication(getApplicationResponse.result)
        : useGroupedRoles(getRolesResponse.result),
      listbox,
      loading,
      error,
      toggle: (event: Event) => {
        isEnabled.value = true;
        listbox.value?.toggle(event);
      },
    };
  },
});

function useGroupedRoles(rolesQuery: Ref<GetRolesQuery | undefined>) {
  const groupedRoles = ref<GroupedItems<RoleType>[]>([]);

  watch(
    () => useResult(rolesQuery).value,
    (newValue) => {
      const map: { [name: string]: RoleType[] } = {};
      newValue?.forEach((element) => {
        if (element?.application?.name && map[element.application.name]) {
          map[element.application.name].push(element);
        } else if (element?.application?.name) {
          map[element.application.name] = [element];
        }
      });
      groupedRoles.value = Object.keys(map)
        .sort()
        .map((element) => {
          return {
            name: element,
            items: map[element].sort(
              (a, b) => a.name?.localeCompare(b.name ?? "") ?? -1
            ),
          };
        });
    }
  );

  return groupedRoles;
}

function useGroupedRolesByApplication(
  rolesQuery: Ref<GetApplicationQuery | undefined>
) {
  const groupedRoles = ref<GroupedItems<RoleType>[]>([]);

  watch(
    () => useResult(rolesQuery).value,
    (newValue) => {
      const map: { [name: string]: RoleType[] } = {};

      newValue?.roles?.forEach((element) => {
        if (element != null) {
          if (newValue?.name && map[newValue.name]) {
            map[newValue.name].push(element);
          } else if (newValue?.name) {
            map[newValue.name] = [element];
          }
        }
      });

      groupedRoles.value = Object.keys(map)
        .sort()
        .map((element) => {
          return {
            name: element,
            items: map[element].sort(
              (a, b) => a.name?.localeCompare(b.name ?? "") ?? -1
            ),
          };
        });
    }
  );

  return groupedRoles;
}
